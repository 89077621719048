import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '@icons/wolfkit-light/arrow-small-right-light.svg';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import Button from '@shared/ui/buttons/Button';
import Banner from '@shared/ui/display/Banner';
const GetVerificationBanner = () => {
    const { t } = useTranslation();
    return (_jsx(Banner, { text: t('profile.get_verification.text', { ns: 'common' }), fontSize: '16px', customIcon: (_jsx(VerificationIcon, { size: 24, verified: true })), additionalButtons: (_jsx(Button, { variant: 'plain', size: 'small', endIcon: ArrowRightIcon, children: t('profile.get_verification.btn_text', { ns: 'common' }) })), hideCloseButton: true }));
};
export default GetVerificationBanner;
