import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { getPortfolioTabRouteName, getProfilePage, getProfileTabRouteName } from '../lib';
const useProfileNavigation = () => {
    const location = useLocation();
    const { navigate } = useAppNavigation();
    const profilePage = getProfilePage(location.pathname);
    const navigateToProfileTab = useCallback((tab, options = {}) => {
        if (!profilePage) {
            return;
        }
        const routeName = getProfileTabRouteName(tab, profilePage);
        if (routeName) {
            navigate(routeName, options);
        }
    }, [profilePage, navigate]);
    const navigateToPortfolioTab = useCallback((tab, options = {}) => {
        if (!profilePage) {
            return;
        }
        const routeName = getPortfolioTabRouteName(tab, profilePage);
        if (routeName) {
            navigate(routeName, options);
        }
    }, [profilePage, navigate]);
    return {
        profilePage,
        navigateToProfileTab,
        navigateToPortfolioTab,
    };
};
export default useProfileNavigation;
