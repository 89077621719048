import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import Icon from '@shared/ui/icons/Icon';
const Container = styled.span(() => ({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '3px',
}));
const Text = styled.span(props => ({
    display: 'inline',
    fontWeight: 500,
    color: props.isComplete ? props.theme.palette.primary.main : props.theme.palette.text.primary,
}));
const CompletionIconText = ({ className = undefined, IconComponent, isComplete, children = undefined, }) => {
    const theme = useTheme();
    const iconColor = isComplete ?
        theme.palette.primary.main :
        theme.customColors.input.borderHover;
    return (_jsxs(Container, { className: className, children: [_jsx(Icon, { size: 12, IconComponent: IconComponent, color: iconColor, baseComponentType: 'span' }), !!children && (_jsx(Text, { isComplete: isComplete, children: children }))] }));
};
export default CompletionIconText;
