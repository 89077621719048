import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { ReactComponent as CheckmarkIconComponent } from '@icons/wolfkit-solid/checkmark-without-check-circle-solid.svg';
import Banner from '@shared/ui/display/Banner';
import CompletionIconText from './CompletionIconText';
const BannerStyled = styled(Banner)((props) => ({
    borderLeft: `6px solid ${props.theme.palette.primary.main}`,
    borderBottomLeftRadius: props.theme.shape.borderRadius,
    borderTopLeftRadius: props.theme.shape.borderRadius,
}));
const CompleteProfileBanner = ({ isLocationFilled = false, isPublicNameFilled = false, onClose = undefined, }) => (_jsx(BannerStyled, { text: Trans({
        ns: 'translation',
        i18nKey: 'profile.complete_profile',
        components: {
            PublicName: (_jsx(CompletionIconText, { isComplete: isPublicNameFilled, IconComponent: CheckmarkIconComponent })),
            Location: (_jsx(CompletionIconText, { isComplete: isLocationFilled, IconComponent: CheckmarkIconComponent })),
        },
    }), fontSize: '16px', onCloseClick: onClose, hideIcon: true }));
export default CompleteProfileBanner;
